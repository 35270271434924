<template>
  <div class="slipGajiPegawai">
    <a-modal
      :visible="showModal"
      @cancel="modalInteraction"
      :title="titleModal"
      :width="900"
      centered
    >
      <EmptyState
        v-if="currentPeriodStatus === 'berjalan'"
        heading="Absensi Sedang Berjalan"
      />
      <ModalRincianGaji v-else :dataDetil="dataDetil" />
      <template slot="footer">
        <a-button class="d-none" key="back"></a-button>
        <a-button class="d-none" key="submit"></a-button>
      </template>
    </a-modal>
    <div class="d-flex align-items-center mb-4">
      <div class="mr-3">
        <a-select size="large" class="w-100" @change="fetchDataTable" v-model="selectYear">
          <a-select-option value="" >Semua Tahun</a-select-option>
          <a-select-option v-for="(item, index) in years" :key="index" :value="item">
            {{item}}
          </a-select-option>
        </a-select>
      </div>
      <div class="ml-auto">
        <a-button size="large" @click.prevent="fetchDataTable"><a-icon type="sync" />Sinkronkan</a-button>
      </div>
    </div>
     <a-table
        @change="handleTableChange"
        :columns="gajiColumns"
        :loading="loading.table"
        :pagination="pagination"
        :data-source="dataTable" bordered
      >
        <div slot="periode" slot-scope="value, record">
          <a
            @click.prevent="modalInteraction(record)"
            class="text-primary periodeAnchor"
            >{{record.bulan + ' ' + record.tahun}}</a
          >
        </div>
        <div slot="gaji" slot-scope="value, record">
          <div class="text-center" v-if="record.statusPeriode == 'berjalan'">
            <a-tag color='orange'> ABSENSI MASIH BERJALAN </a-tag>
          </div>
          <span v-else-if="!value">-</span>
          <span v-else>Rp {{thousandPrice(Number(value))}}</span>
        </div>
        <div slot="status" slot-scope="value">
          <a-tag class="payment-status" :color="value === 'paid' ? 'green' : 'red'">
            {{value === 'paid' ? 'TERBAYAR' : 'BELUM TERBAYAR'}}
          </a-tag>
        </div>
        <div class="d-flex flex-column" slot="aksi" slot-scope="value, record">
          <a-button :disabled="record.status !== 'paid'" @click.prevent="generateReport(record)" size="large" class="mt-3 border-danger text-danger w-100"><a-icon type="file-pdf" />Slip Gaji</a-button>
          <!-- <a-button size="large" type="danger"><a-icon type="file-pdf" />Export Payslip</a-button> -->
        </div>
      </a-table>
      <vue-html2pdf
      :html-to-pdf-options="pdfOptions"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      :paginate-elements-by-height="930"
      filename="hahaha"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      ref="html2Pdf"
      @beforeDownload="beforeDownload($event)"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
    >
      <section slot="pdf-content">
        <slip-gaji :configSchool="configSchool" :slipGaji="slipSelected" />
      </section>
    </vue-html2pdf>
  </div>
</template>
<script>
import thousandPrice from '@/helpers/thousandPrice'
import config from '@/config'
import VueHtml2pdf from 'vue-html2pdf'
const ModalRincianGaji = () => import('@/components/app/AdminKeuangan/ModalRincianGaji')
const SlipGaji = () => import('@/components/app/Shared/SlipGaji')
const EmptyState = () => import('@/components/app/EmptyState')

const gajiColumns = [
  {
    title: 'Periode',
    dataIndex: 'periode',
    key: 'periode',
    scopedSlots: { customRender: 'periode' },
  },
  {
    title: 'Gaji yang Diterima (Rp)',
    dataIndex: 'gaji',
    key: 'gaji',
    align: 'right',
    scopedSlots: { customRender: 'gaji' },
  },
  {
    title: 'Status Gaji',
    key: 'status',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
    align: 'center',
  },
  {
    title: 'Aksi',
    key: 'aksi',
    dataIndex: 'aksi',
    scopedSlots: { customRender: 'aksi' },
    align: 'center',
  },
]

export default {
  data() {
    return {
      gajiColumns,
      loading: {
        table: false,
      },
      configSchool: config.school,
      pagination: { pageSize: 10, hideOnSinglePage: true },
      dataTable: [],
      thousandPrice,
      slipSelected: {},
      pdfOptions: {
        margin: [5, 5, 5, 5],
        filename: '',
        image: {
          type: 'jpeg',
          quality: 0.90,
        },
        html2canvas: {
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          // unit: 'in',
          format: 'a4',
          orientation: 'portrait',
        },
      },
      showModal: false,
      titleModal: '',
      dataDetil: {},
      selectYear: '',
      years: [],
      currentPeriodStatus: null,
    }
  },
  props: {
    dataPegawai: Object,
  },
  components: { SlipGaji, VueHtml2pdf, ModalRincianGaji, EmptyState },
  methods: {
    modalInteraction(detail) {
      this.showModal = !this.showModal
      setTimeout(() => {
        if (this.showModal) {
          this.titleModal = `Rincian Gaji Bulan ${detail.bulan} Tahun ${detail.tahun}`
          const metadata = detail.metadata
          const dataOthers = metadata ? metadata.data : detail
          const { namaPegawai: nama, arrNamaJabatan: jabatan, namaGolongan: golongan, namaUnit: unit } = this.dataPegawai
          const dataUser = {
            nama,
            jabatan,
            golongan,
            unit,
          }
          // let data = {
          //   gaji_pokok: metadata,
          //   penghasilan: [],
          //   pengurangan: [],
          //   total_gaji: '',
          //   total_penghasilan: '',
          //   total_pengurangan: '',
          //   jumlah_hari_kerja: '',
          //   hari_kerja_normal: '',
          // }
          this.dataDetil = {
            key: detail.key,
            ...dataUser,
            ...dataOthers,
          }
          this.currentPeriodStatus = detail.statusPeriode
        } else {
          this.dataDetil = {}
          this.currentPeriodStatus = null
        }
      }, 100)
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      let order = ''
      pager.current = pagination.current
      this.pagination = pager
      if (sorter.order === 'ascend') {
        order = 'ASC'
      } else if (sorter.order === 'descend') {
        order = 'DESC'
      } else {
        order = 'ASC'
      }
      this.fetchDataTable({
        page: pagination.current,
        order: order,
      })
    },
    fetchDataTable(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      const idPegawai = this.user.id
      this.loading.table = true
      this.$store.dispatch('absensiKaryawan/FETCH_RINCIAN_GAJI_BY_ID', { ...params, idPegawai, year: this.selectYear })
        .then(data => {
          console.log(data)
          this.dataTable = data.map(periode => {
            const rekapAbsen = periode.rekap_absens[0]
            const dataGaji = rekapAbsen.rincian_gaji?.status_gaji === 'paid' ? rekapAbsen.rincian_gaji.metadata?.data : rekapAbsen.rincian_gaji
            // console.log(rekapAbsen, dataGaji)
            const penghasilan = rekapAbsen.rincian_gaji ? rekapAbsen.rincian_gaji.penghasilan : []
            const pengurangan = rekapAbsen.rincian_gaji ? rekapAbsen.rincian_gaji.pengurangan : []
            return {
              key: periode.id,
              bulan: periode.bulan,
              tahun: periode.tahun,
              gaji: dataGaji?.total_gaji,
              status: rekapAbsen.rincian_gaji?.status_gaji,
              metadata: rekapAbsen.rincian_gaji?.metadata,
              statusPeriode: periode.status_periode,
              hari_kerja_normal: rekapAbsen.hari_kerja_normal,
              tambahan_hari_kerja: rekapAbsen.tambahan_hari_kerja,
              tanggal_pembayaran: rekapAbsen.rincian_gaji?.tanggal_pembayaran,
              total_gaji: rekapAbsen.rincian_gaji?.total_gaji,
              total_penghasilan: rekapAbsen.rincian_gaji?.total_penghasilan,
              total_pengurangan: rekapAbsen.rincian_gaji?.total_pengurangan,
              penghasilan,
              pengurangan,
              gaji_pokok: rekapAbsen.rincian_gaji?.gaji_pokok,
            }
          })
          this.loading.table = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    generateReport(data) {
      // this.slipSelected = dataSlipGaji
      this.slipSelected = data.metadata
      // console.log(this.slipSelected)
      this.$refs.html2Pdf.generatePdf()
    },
    onProgress(event) {
      console.log('Progress  ', event)
    },
    hasStartedGeneration() {
      console.log('Start Generate')
    },
    hasGenerated(event) {
      console.log('Generated', event)
    },
    async beforeDownload ({ html2pdf, options, pdfContent }) {
      this.pdfOptions.filename = `${this.slipSelected.pegawai.nama.split(',')[0]} SLIP GAJI`
      await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
        // const totalPages = pdf.internal.getNumberOfPages()
        // for (let i = 1; i <= totalPages; i++) {
        //   pdf.setPage(i)
        //   pdf.setFontSize(10)
        //   pdf.setTextColor(150)
        //   pdf.text('Hasan Educational Technology', (pdf.internal.pageSize.getWidth() * 0.50), (pdf.internal.pageSize.getHeight() - 2))
        //   pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 2))
        // }
      }).save()
    },
  },
  created() {
    this.$store.dispatch('absensiKaryawan/FETCH_PERIODE_KEHADIRAN', { page: 'all' })
      .then(datas => {
        // console.log(datas)
        // this.column
        datas.forEach(periode => {
          if (!this.years.includes(periode.tahun)) this.years.push(periode.tahun)
        })
        // this.years = dataTahun.map(data => { return data.tahun })
        return this.fetchDataTable()
      })
  },
  computed: {
    user() { return this.$store.state.user.user },
  },
}
</script>
<style lang="scss">
.slipGajiPegawai {
  .periodeAnchor {
    &:hover {
      color: var(--kit-color-primary)!important;
      text-decoration: underline;
      opacity: 0.9;
    }
  }
}
</style>
